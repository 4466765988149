const passwordMinLength = 12;
const largeScreen = '820px';
const smallScreen = '620px';
const extraSmallScreen = '349px';

export const environment = {
  production: true,
  name: 'prod',
  baseURL: 'https://apg.aventi.no/#/',
  firebase: {
    apiKey: 'AIzaSyCcpuRaw7MSWK0gz7UR6uuA5pMqRQA1PAk',
    authDomain: 'apg.aventi.no',
    databaseURL: 'https://aventiapg.firebaseio.com',
    projectId: 'aventiapg',
    storageBucket: 'aventiapg.appspot.com',
    messagingSenderId: '1002632071817',
    appId: '1:1002632071817:web:dcc7f0672341ecf9439458',
    measurementId: 'G-K8CZQKBJFW',
    cacheSizeBytes: 1000
  },
  mapsapikey: 'AIzaSyB-D7UEq1YMlNYIeKmZszb3aOvzOBZBj_E',
  mapIdDarkmode: '6a870ae00b4cc754',
  largeScreen,
  smallScreen,
  extraSmallScreen,
  mobileQueryMaxwidthLarge: '(max-width: ' + largeScreen + ')',
  mobileQueryMaxwidthSmall: '(max-width: ' + smallScreen + ')',
  mobileQueryMaxwidthExtraSmall: '(max-width: ' + extraSmallScreen + ')',
  supportemail: 'apg@aventi.no',
  appVersion: '1.5.10',
  passwordMinLength,
  passwordRegExMinLength:
    new RegExp(`^.{${(passwordMinLength)},}$`),
  passwordRegExUpperCaseLetter:
    new RegExp(`^(?=.*[A-Z]).+$`),
  passwordRegExLowerCaseLetter:
    new RegExp(`^(?=.*[a-z]).+$`),
  passwordRegExNumber:
    new RegExp(`^(?=.*\\d).+$`),
  passwordRegExSpecialCharacter:
    new RegExp(`^(?=.*[@$!_:¨~≈\\]\\[∞€£™©±´\`\"\'§<>\\-;^%*?&#¢\\/|+=(){}.,\\\\]).+$`),
};
