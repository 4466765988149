import {Location} from '@angular/common';
import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreDocument, DocumentChangeAction} from '@angular/fire/compat/firestore';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Router} from '@angular/router';
import firebase from 'firebase/compat/app';

import {Observable, Subject} from 'rxjs';
import {map, take, takeUntil} from 'rxjs/operators';
import {ConfirmComponent} from '../components/common/bottom-sheet/confirm/confirm.component';
import {AventiObject} from '../models/AventiObject';
import {Checklist} from '../models/Checklist';
import {ChecklistItem} from '../models/Checklistitem';
import {AventiFile} from '../models/File';
import {User, UserTimestamp} from '../models/User';
import {DbpathService} from './dbpath.service';
import {IdService} from './id.service';
import {SharedService} from './shared.service';
import {SnackbarService} from './snackbar.service';
import {DateService} from './date.service';
import {ObjectStatus} from '../models/Status';
import _ from 'underscore';

@Injectable({
  providedIn: 'root',
})
export class ChecklistService {
  checklist: Checklist = new Checklist();
  private ngUnsubscribe = new Subject();

  constructor(
    private afs: AngularFirestore,
    private idservice: IdService,
    private router: Router,
    // private locationservice: LocationService,
    private snackbarservice: SnackbarService,
    private dbpathservice: DbpathService,
    private dialog: MatDialog,
    private sharedservice: SharedService,
    private bottomSheet: MatBottomSheet,
    private dateservice: DateService,
    private location: Location,
  ) {
  }

async groupChecklistItems(
  object: AventiObject,
  checklist: Checklist,
  isReceiptform: boolean,
  useStatusLevel: boolean,
  allStatuses: ObjectStatus[],
  allAdminChecklistItems: ChecklistItem[],
  checklistItems: ChecklistItem[] = [],
) {
  let statuses: ObjectStatus[] = [];
  const statusNames: string[] = [];
  const statusCount: Record<string, { count: number, checked: number, priority: number }> = {};
  let sortedAllStatuses: ObjectStatus[] = [];
  let isLoading = true;
  let adminChecklistItems: ChecklistItem[] = [];

  if (checklist) {
    if (isReceiptform) {
      statuses = [{ id: 'receiptForm' }];
    } else {
      const allAdminChecklistItemsNonNull = _.reject(allAdminChecklistItems, _.isNull);
      adminChecklistItems = allAdminChecklistItemsNonNull.filter(acli => acli.parentid === object.type.id);
      sortedAllStatuses = _.sortBy(allStatuses, 'priority');

      if (useStatusLevel) {
        const tempStatuses: ObjectStatus[] = [];
        const withoutStatusName = 'Uten statusnivå';
        statusCount[withoutStatusName] = { count: 0, checked: 0, priority: -1 };

        for (const item of adminChecklistItems) {
          const itemToGetStatusFrom = adminChecklistItems.find(cli => cli?.text === item?.text);
          item.statusLevel = itemToGetStatusFrom?.statusLevel;
          if (itemToGetStatusFrom?.statusLevel && !tempStatuses.some(status => status?.id === itemToGetStatusFrom.statusLevel?.id)) {
            const status = allStatuses.find(st => st.id === itemToGetStatusFrom.statusLevel?.id);
            tempStatuses.push(status);
            statusNames.push(status.name);
          }
        }

        checklistItems.forEach(checklistitem => {
          checklistitem.statusLevel = allAdminChecklistItems.find(cli => cli.text === checklistitem.text)?.statusLevel;
        });

        for (const status of tempStatuses) {
          const count = adminChecklistItems.filter(checklistitem => checklistitem.statusLevel?.id === status.id).length;
          const checked = checklistItems.filter(checklistitem =>
            status.id === checklistitem.statusLevel?.id &&
            (checklistitem.status === 'not_applicable' ||
              checklistitem.status === 'ok' ||
              (checklistitem.value && checklistitem.value.length > 0 &&
              checklistitem.valuecomment && checklistitem.valuecomment.length > 0) ||
              (checklistitem.comment && checklistitem.comment?.length > 0) ||
              (checklistitem.type.name === 'Ekstern dokumentasjon'))
          ).length;
          statusCount[status.name] = { count, checked, priority: status.priority };
        }

        if (statusCount[withoutStatusName].count > 0 || Object.keys(statusCount).length === 1) {
          tempStatuses.push({ name: 'Uten statusnivå' });
        }

        statuses = tempStatuses;
      }
    }
    isLoading = false;
  }

  return {
    object,
    statuses,
    statusNames,
    statusCount,
    sortedAllStatuses,
    isLoading,
    adminChecklistItems
  };
}

  getChecklists() {
    const projectid = this.idservice.projectId();
    return (
      this.afs.collectionGroup(
        'checklists',
        (ref) =>
          ref
            .where('projectid', '==', projectid)
      )
        .snapshotChanges()
        .pipe(
          map((actions) => {
            return actions.map((action) => {
              const checklist = {...action.payload.doc.data() as Checklist};
              const id = action.payload.doc.id;
              checklist.parentid = action.payload.doc.ref.parent.parent.id;
              if (action.payload.doc.ref.parent.parent.parent.parent) {
                checklist.projectid = action.payload.doc.ref.parent.parent.parent.parent.id;
              }
              checklist.editedBy = this.dateservice.getLatestEditedByFromArray(checklist.editedBy);
              delete checklist.z_checklistitemsstring;
              delete checklist.z_pdfexportsstring;
              delete checklist.z_filesstring;
              const fromCache = action.payload.doc.metadata.fromCache;
              return {id, fromCache, ...checklist} as Checklist;
            });
          })
        )
    );
  }

  getChecklistItems(checklistid: string, isReceiptform: boolean, checklist?: Checklist): Observable<ChecklistItem[]> {

    let path: string;
    if (checklist) {
      path = isReceiptform ? this.dbpathservice.dbPathReceiptformItems() : this.dbpathservice.dbPathChecklistItems(false, checklist);
    } else {
      path = isReceiptform ? this.dbpathservice.dbPathReceiptformItems() : this.dbpathservice.dbPathChecklistItems();
    }

    return this.afs.collection(path, (ref) =>
      ref.orderBy('type').where('checklistid', '==', checklistid)
    ).snapshotChanges()
      .pipe(
        map((actions) => {
          return this.stripChecklistItems(actions);
        })
      );
  }

  async create(
    aventiobject: AventiObject,
    redirect: boolean = false,
    projectid?: string,
    resetGlobalLoadingSpinner: boolean = true
  ) {
    if (!projectid) {
      projectid = this.idservice.projectId();
    }

    const tags = aventiobject.tags || null;
    const lastupdated = firebase.firestore.Timestamp.now();
    const editedBy: UserTimestamp[] = [];
    const checklist: Checklist = {
      name: aventiobject.name,
      desc: aventiobject.desc,
      type: aventiobject.type,
      tags,
      profilenumber: aventiobject.profilenumber || null,
      process: aventiobject.process || null,
      pos: aventiobject.pos || null,
      status: 'not_started',
      editedBy,
      lastupdated,
      objectid: aventiobject.id,
      projectid,
    };

    console.log('Getting checklist reference...');
    const docRefChecklist = this.afs.firestore
      .collection(
        this.dbpathservice.dbPathChecklists(aventiobject.id, projectid)
      )
      .doc();
    checklist.id = docRefChecklist.id;
    console.log('Getting types...');
    this.sharedservice.checklistitemtypes.pipe(take(1)).subscribe((types) => {
      if (types !== null) {
        this.sharedservice.online.pipe(take(1)).subscribe(async online => {
          console.log(`Creating batch while ${online ? 'online' : 'offline'}`);
          const batch = this.afs.firestore.batch();

          if (online) {
            batch.set(docRefChecklist, checklist);
          } else {
            docRefChecklist.set(checklist)
              .then(i => {
                console.log('checklist added', i);
              })
              .catch((err) => {
                console.error('Error while creating checklist: ', err);
                return err;
              });
          }
          const items: ChecklistItem[] = [];
          const typesPromises = types.map(async type => {
            if (type.parentid === checklist.type?.id) {
              const docRefItem = this.afs.firestore
                .collection(`${docRefChecklist.path}/checklistitems`)
                .doc();
              const data: ChecklistItem = {
                id: docRefItem.id,
                checklistid: docRefChecklist.id,
                objectid: checklist.objectid,
                projectid,
                comment: '',
                value: '',
                lastupdated,
                type: type.type,
                text: type.text,
              };
              items.push(data);
              if (online) {
                console.log('Adding item to batch');
                batch.set(docRefItem, data);
              } else {
                console.log('Creating item');
                docRefItem.set(data)
                  .then(i => {
                    console.log('item added', i);
                  })
                  .catch((err) => {
                    console.error('Error while creating checklistItem: ', err);
                    return err;
                  });
              }
            }
          });
          await Promise.all(typesPromises);

          if (online) {
            console.log('Comitting batch...');
            batch
              .commit()
              .then(() => {
                console.log('Still comitting batch...');
                this.sharedservice.checklists
                  .pipe(take(1))
                  .subscribe((checklists) => {
                    console.log('Got checklists...', checklists);
                    if (checklists !== null) {
                      console.log('Checklists not null...');
                      checklist.checklistitems = items;
                      checklists.push(checklist);
                      this.sharedservice.checklists.next(checklists);
                      if (resetGlobalLoadingSpinner) {
                        this.sharedservice.isLoading.next([false, '', ['']]);
                      }
                      if (redirect) {
                        this.router.navigateByUrl(
                          'p/' +
                          projectid +
                          '/o/' +
                          checklist.objectid +
                          '/s/' +
                          checklist.id
                        );
                        this.dialog.closeAll();
                      }
                    }
                  });
              })
              .catch((err) => {
                console.log(err);
                this.snackbarservice.openSnackBar(
                  'Det skjedde en feil under oppretting av sjekkliste...',
                  ''
                );
              });
          } else {
            this.sharedservice.checklists
              .pipe(take(1))
              .subscribe((checklists) => {
                console.log('Got checklists...', checklists);
                if (checklists !== null) {
                  console.log('Checklists not null...');
                  checklist.checklistitems = items;
                  checklists.push(checklist);
                  this.sharedservice.checklists.next(checklists);
                  if (resetGlobalLoadingSpinner) {
                    this.sharedservice.isLoading.next([false, '', ['']]);
                  }
                  if (redirect) {
                    this.router.navigateByUrl(
                      'p/' +
                      projectid +
                      '/o/' +
                      checklist.objectid +
                      '/s/' +
                      checklist.id
                    );
                    this.dialog.closeAll();
                  }
                }
              });
          }
        });
      }
    });
  }

  getAllChecklistItems() {
    const projectid = this.idservice.projectId();
    return this.afs
      .collectionGroup('checklistitems', (ref) =>
        ref.where('projectid', '==', projectid)
      ).snapshotChanges()
      .pipe(
        map((actions) => {
          return this.stripChecklistItems(actions);
        })
      );
  }

  getAllChecklistItemsWithDeviation() {
    const projectid = this.idservice.projectId();
    return this.afs
      .collectionGroup('checklistitems', (ref) =>
        ref.where('projectid', '==', projectid)
          .where('status', '==', 'deviation')
      ).snapshotChanges()
      .pipe(
        map((actions) => {
          return this.stripChecklistItems(actions);
        })
      );
  }

  getChecklistItem(checklistItemId: string): Observable<ChecklistItem> {
    const doc: AngularFirestoreDocument<ChecklistItem> = this.afs.doc(this.dbpathservice.dbPathChecklistItem(checklistItemId));
    return doc.snapshotChanges().pipe(
      map((action) => {
        const id = action.payload.id;
        const checklistItem = action.payload.data();
        const fromCache = action.payload.metadata.fromCache;
        return {id, fromCache, ...checklistItem} as ChecklistItem;
      }));
  }

  getAllChecklistPDFs(): Observable<AventiFile[]> {
    const projectid = this.idservice.projectId();
    return this.afs
      .collectionGroup('pdfexports', (ref) =>
        ref.where('projectid', '==', projectid)
      )
      .valueChanges();
  }

  getChecklistFiles(checklistid: string): Observable<AventiFile[]> {
    const projectid = this.idservice.projectId();
    return this.afs
      .collectionGroup('files', (ref) =>
        ref
          .where('projectid', '==', projectid)
          .where('checklistid', '==', checklistid)
      )
      .valueChanges();
  }

  getChecklistFilesForChecklistItem(checklistid: string, checklistitemid: string): Observable<AventiFile[]> {
    const projectid = this.idservice.projectId();
    return this.afs
      .collectionGroup('files', (ref) =>
        ref
          .where('projectid', '==', projectid)
          .where('checklistid', '==', checklistid)
          .where('checklistitemid', '==', checklistitemid)
      )
      .valueChanges();
  }

  getChecklistItemFiles(checklistId: string, checklistItemId: string): Observable<AventiFile[]> {
    return this.afs
      .collectionGroup('files', (ref) =>
        ref
          .where('checklistid', '==', checklistId)
          .where('checklistitemid', '==', checklistItemId)
      )
      .valueChanges();
  }

  getAllChecklistItemFiles(checklistId: string): Observable<AventiFile[]> {
    return this.afs
      .collectionGroup('files', (ref) =>
        ref
          .where('checklistid', '==', checklistId)
          .where('checklistitemid', '!=', null)
      )
      .valueChanges();
  }

  async updateChecklist(objectid?: string, checklist?: Checklist) {
    await this.dbDocChecklist(checklist, objectid).update(checklist);
  }

  async updateChecklistStatus(status: string, checklist?: Checklist) {
    // this.projectChecklistsStatus();
    // const statusHuman = this.humanizeStatus(status);
    let isSigned = false;
    if (status === 'signed') {
      isSigned = true;
    }
    await this.dbDocChecklist(checklist).update({
      status,
      isSigned,
      generatingPDFstatus: firebase.firestore.FieldValue.delete(),
      // statusHuman,
      // })
      // .then(() => {
      //   this.globalservice.updateParentParent(
      //     this.dbpathservice.dbPathChecklist(
      //       checklist,
      //       checklist.objectid,
      //       checklist.projectid
      //     )
      //   );
    });
  }

  updateChecklistGeneratingPDF(generatingPDF: boolean, checklist: Checklist) {
    if (generatingPDF) {
      this.dbDocChecklist(checklist).update({
        // generatingPDF,
        generatingPDFchecklist: JSON.stringify(checklist),
        // generatingPDFchecklistitems: JSON.stringify(checklist.checklistitems)
        // })
        // .then(() => {
        //   this.globalservice.updateParentParent(
        //     this.dbpathservice.dbPathChecklist(
        //       checklist,
        //       checklist.objectid,
        //       checklist.projectid
        //     )
        //   );
      });
    } else {
      this.dbDocChecklist(checklist).update({
        generatingPDF: firebase.firestore.FieldValue.delete(),
        generatingPDFstatus: firebase.firestore.FieldValue.delete(),
        generatingPDFchecklist: firebase.firestore.FieldValue.delete(),
        // generatingPDFchecklistitems: firebase.firestore.FieldValue.delete(),
        // })
        // .then(() => {
        //   this.globalservice.updateParentParent(
        //     this.dbpathservice.dbPathChecklist(
        //       checklist,
        //       checklist.objectid,
        //       checklist.projectid
        //     )
        //   );
      });
    }
  }

  updateChecklistDeliveredPdfToCustomer(
    status: boolean,
    checklist: Checklist,
    user: User
  ) {
    this.dbDocChecklist(checklist).update({
      PDFDeliveredToCustomer: {
        status,
        editedBy: firebase.firestore.FieldValue.arrayUnion({
          status,
          user,
          timestamp: firebase.firestore.Timestamp.now(),
        }),
      },
      // })
      // .then(() => {
      //   this.globalservice.updateParentParent(
      //     this.dbpathservice.dbPathChecklist(
      //       checklist,
      //       checklist.objectid,
      //       checklist.projectid
      //     )
      //   );
    });
  }

  updateChecklistitemValue(
    checklistItemId: string,
    value: string,
    silenced: boolean = false,
    isReceiptform?: boolean
  ) {
    if (silenced) {
      this.sharedservice.user.pipe(take(1)).subscribe((u) => {
        if (u !== null) {
          const user = u[0];
          this.dbDocChecklistItem(checklistItemId, isReceiptform).update({
            status: 'no_value',
            value,
            editedBy: firebase.firestore.FieldValue.arrayUnion({
              user,
              timestamp: firebase.firestore.Timestamp.now(),
            }),
            lastupdated: firebase.firestore.Timestamp.now(),
            // })
            // .then(() => {
            //   this.globalservice.updateParentParent(
            //     this.dbpathservice.dbPathChecklistItem(checklistItemId)
            //   );
          });
        }
      });
    } else {
      // this.locationservice
      //   .getPosition()
      //   .then(() => {
      // let status = 'no_value';
      // if (value.length > 0 && value.length > 0) {
      //   status = 'ok';
      // }
      this.sharedservice.user.pipe(take(1)).subscribe((u) => {
        if (u !== null) {
          const user = u[0];
          this.dbDocChecklistItem(checklistItemId, isReceiptform).update({
            // id: '',
            // status,
            value,
            signedBy: {
              user,
              timestamp: firebase.firestore.Timestamp.now(),
            },
            editedBy: firebase.firestore.FieldValue.arrayUnion({
              user,
              timestamp: firebase.firestore.Timestamp.now(),
            }),
            lastupdated: firebase.firestore.Timestamp.now(),
            // location: pos,
            // })
            // .then(() => {
            //   this.globalservice.updateParentParent(
            //     this.dbpathservice.dbPathChecklistItem(checklistItemId)
            //   );
          });
          // this.dbDocChecklist().update({
          //   editedBy: firebase.firestore.FieldValue.arrayUnion({
          //     user,
          //     timestamp: firebase.firestore.Timestamp.now(),
          //   }),
          //   lastupdated: firebase.firestore.Timestamp.now(),
          // });
        }
      });
      // })
      // .catch((err) => {
      //   console.log(err);
      // });
    }
  }

  updateChecklistitemValueComment(
    checklistItemId: string,
    valuecomment: string,
    isReceiptform?: boolean
  ) {
    // this.locationservice
    //   .getPosition()
    //   .then(() => {
    // let status = 'no_value';
    // if (valuecomment.length > 0) {
    //   status = 'ok';
    // }
    this.sharedservice.user.pipe(take(1)).subscribe((u) => {
      if (u !== null) {
        const user = u[0];
        this.dbDocChecklistItem(checklistItemId, isReceiptform).update({
          // id: '',
          // status,
          valuecomment,
          signedBy: {
            user,
            timestamp: firebase.firestore.Timestamp.now(),
          },
          editedBy: firebase.firestore.FieldValue.arrayUnion({
            user,
            timestamp: firebase.firestore.Timestamp.now(),
          }),
          lastupdated: firebase.firestore.Timestamp.now(),
          // location: pos,
          // })
          // .then(() => {
          //   this.globalservice.updateParentParent(
          //     this.dbpathservice.dbPathChecklistItem(checklistItemId)
          //   );
        });
        // this.dbDocChecklist().update({
        //   editedBy: firebase.firestore.FieldValue.arrayUnion({
        //     user,
        //     timestamp: firebase.firestore.Timestamp.now(),
        //   }),
        //   lastupdated: firebase.firestore.Timestamp.now(),
        // });
      }
    });
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  }

  updateChecklistitemStatus(
    checklistItemId: string,
    status: string,
    isReceiptform?: boolean
  ) {
    // console.log('id:', checklistItemId);
    // this.locationservice
    //   .getPosition()
    //   .then(() => {
    // if (pos.message) {
    //   pos = null;
    // }
    this.sharedservice.user.pipe(take(1)).subscribe((u) => {
      if (u !== null) {
        const user = u[0];
        // if (pos.error) {
        //   this.snackbarservice.openSnackBar(
        //     pos.lng + ' - ' + pos.lat + ' - ' + pos.error,
        //     ''
        //   );
        // }
        this.dbDocChecklistItem(checklistItemId, isReceiptform).update({
          // id: '',
          status,
          signedBy: {
            user,
            timestamp: firebase.firestore.Timestamp.now(),
          },
          editedBy: firebase.firestore.FieldValue.arrayUnion({
            user,
            timestamp: firebase.firestore.Timestamp.now(),
          }),
          lastupdated: firebase.firestore.Timestamp.now(),
          // location: pos,
          // })
          // .then(() => {
          //   this.globalservice.updateParentParent(
          //     this.dbpathservice.dbPathChecklistItem(checklistItemId)
          //   );
        });
        // this.dbDocChecklist().update({
        //   editedBy: firebase.firestore.FieldValue.arrayUnion({
        //     user,
        //     timestamp: firebase.firestore.Timestamp.now(),
        //   }),
        //   lastupdated: firebase.firestore.Timestamp.now(),
        // });
      }
    });
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  }

  updateChecklistitemComment(
    checklistItemId: string,
    comment: string,
    isReceiptform?: boolean
  ) {
    // this.locationservice
    //   .getPosition()
    //   .then(() => {
    this.sharedservice.user.pipe(take(1)).subscribe((u) => {
      if (u !== null) {
        const user = u[0];
        this.dbDocChecklistItem(checklistItemId, isReceiptform).update({
          // id: '',
          comment,
          signedBy: {
            user,
            timestamp: firebase.firestore.Timestamp.now(),
          },
          editedBy: firebase.firestore.FieldValue.arrayUnion({
            user,
            timestamp: firebase.firestore.Timestamp.now(),
          }),
          lastupdated: firebase.firestore.Timestamp.now(),
          // location: pos,
          // })
          // .then(() => {
          //   this.globalservice.updateParentParent(
          //     this.dbpathservice.dbPathChecklistItem(checklistItemId)
          //   );
        });
        // this.dbDocChecklist().update({
        //   editedBy: firebase.firestore.FieldValue.arrayUnion({
        //     user,
        //     timestamp: firebase.firestore.Timestamp.now(),
        //   }),
        //   lastupdated: firebase.firestore.Timestamp.now(),
        // });
      }
    });
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  }

  updateChecklistitemDeviationComment(
    checklistItemId: string,
    deviationcomment: string
  ) {
    // this.locationservice
    //   .getPosition()
    //   .then(() => {
    this.sharedservice.user.pipe(take(1)).subscribe((u) => {
      if (u !== null) {
        const user = u[0];
        this.dbDocChecklistItem(checklistItemId).update({
          deviationcomment,
          editedBy: firebase.firestore.FieldValue.arrayUnion({
            user,
            timestamp: firebase.firestore.Timestamp.now(),
          }),
          lastupdated: firebase.firestore.Timestamp.now(),
          // location: pos,
        });
      }
    });
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  }

  updateChecklistComment(checklist: Checklist, comment: string) {
    this.sharedservice.user.pipe(take(1)).subscribe((u) => {
      if (u !== null) {
        const user = u[0];
        this.dbDocChecklist(checklist).update({
          comment,
          editedBy: firebase.firestore.FieldValue.arrayUnion({
            user,
            timestamp: firebase.firestore.Timestamp.now(),
          }),
          lastupdated: firebase.firestore.Timestamp.now(),
        });
      }
    });
  }

  updateChecklistSignature(checklist: Checklist) {
    // this.locationservice
    //   .getPosition()
    //   .then(() => {
    this.sharedservice.user.pipe(take(1)).subscribe((u) => {
      if (u !== null) {
        const user = u[0];
        checklist.signedBy = {
          user,
          timestamp: firebase.firestore.Timestamp.now(),
        };
        checklist.isSigned = true;
        checklist.status = 'signed';
        // checklist.location = pos;
        // console.log("Genererer PDF...");
        // this.printchecklistservice.generatePdfWithImages(checklist);
        console.log('Signerer...');
        const doc: AngularFirestoreDocument = this.dbDocChecklist(checklist);
        // if (isReceiptform) {
        //   doc = this.receiptformservice.dbDocReceiptform();
        // } else {
        //   doc = this.dbDocChecklist(checklist);
        // }
        doc
          .update({
            // id: '',
            isSigned: checklist.isSigned,
            signedBy: checklist.signedBy,
            status: checklist.status,
            // statusHuman: 'Signert',
            // location: checklist.location,
            editedBy: firebase.firestore.FieldValue.arrayUnion({
              user,
              timestamp: firebase.firestore.Timestamp.now(),
            }),
            lastupdated: firebase.firestore.Timestamp.now(),
            // generatingPDFchecklist: JSON.stringify(checklist),
          })
          .then(() => {
            // this.globalservice.updateParentParent(
            //   this.dbpathservice.dbPathChecklist(
            //     checklist,
            //     checklist.objectid,
            //     checklist.projectid
            //   )
            // );
            this.snackbarservice.openSnackBar(
              'Sjekkliste signert av ' + user.firstname,
              ''
            );
          });
      }
    });
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  }

  async deleteChecklist(checklist: Checklist, redirect: boolean = true) {
    // let id: string;
    // if (checklist) {
    //   id = checklist.id;
    // } else {
    //   id = this.idservice.checklistId();
    // }
    let path = this.dbpathservice.dbPathChecklist(checklist);

    if (checklist.checklistitems) {
      for (const item of checklist.checklistitems) {
        this.afs
          .doc(this.dbpathservice.dbPathChecklistItem(item.id))
          .delete()
          .then(() => {
            console.log('Checklist item deleted...');
          });
      }
    }

    if (checklist.receiptform) {
      path = this.dbpathservice.dbPathReceiptform(checklist);
    }
    this.afs
      .doc(path)
      .delete()
      .then(() => {
        console.log('Checklist deleted...');
        if (redirect) {
          if (checklist.receiptform) {
            this.router.navigateByUrl('p/' + this.idservice.projectId() + '/mt');
          } else {
            this.router.navigateByUrl('p/' + this.idservice.projectId() + '/sl');
          }
          let text = 'Sletter sjekkliste...';
          if (checklist.receiptform) {
            text = 'Sletter mottaksskjema...';
          }
          this.snackbarservice.openSnackBar(text, '');
          this.sharedservice.checklists.pipe(take(1)).subscribe((checklists) => {
            if (checklists !== null) {
              // const cl = _.without(
              //   checklists,
              //   _.findWhere(checklists, {
              //     id: checklist.id,
              //   })
              // );
              const cls = checklists;
              cls.forEach((cl) => {
                if (cl.id === checklist.id) {
                  cl.scheduledfordelete = true;
                }
              });
              this.sharedservice.checklists.next(cls);
            }
          });
        }
      });
  }

  newChecklistClick(
    object: AventiObject,
    redirect: boolean,
    receiptform = false,
    shouldNavigateBack = true
  ) {
    let title = 'Ny sjekkliste for: ';
    if (receiptform) {
      title = 'Nytt mottakskjema for: ';
    }
    const sheet = this.bottomSheet.open(ConfirmComponent, {
      backdropClass: 'blur-dialog-backdrop',
      data: {
        title: title + object.name,
        text: 'Objekttype: ' + object.type.name,
        yes: {
          name: 'Generér',
          icon: 'fact_check',
        },
        no: {
          name: 'Avbryt',
          icon: 'close',
        },
      },
    });

    sheet
      .afterDismissed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(async (confirm) => {
        if (confirm) {
          if (shouldNavigateBack) {
            this.location.back();
          }
          this.sharedservice.isLoading.next([
            true,
            'Oppretter sjekkliste',
            [object.name, object.type?.name],
          ]);
          console.log(confirm);
          this.create(object, redirect, object.projectid);
        }
      });
  }

  updateDeviationSignature(checklistitem: ChecklistItem) {
    // this.locationservice
    //   .getPosition()
    //   .then(() => {
    this.sharedservice.user.pipe(take(1)).subscribe((u) => {
      if (u !== null) {
        const user = u[0];
        checklistitem.deviationSignedBy = {
          user,
          timestamp: firebase.firestore.Timestamp.now(),
        };
        // checklistitem.deviationSigned = true;
        // checklistitem.status = 'ok';
        // checklistitem.location = pos;
        console.log('Signerer...');
        const doc: AngularFirestoreDocument = this.dbDocChecklistItem(
          checklistitem.id
        );
        doc
          .update({
            // id: '',
            // deviationSigned: checklistitem.deviationSigned,
            deviationSignedBy: checklistitem.deviationSignedBy,
            // status: checklist.status,
            // statusHuman: 'Signert',
            // location: checklistitem.location,
            // editedBy: firebase.firestore.FieldValue.arrayUnion({
            //   user,
            //   timestamp: firebase.firestore.Timestamp.now(),
            // }),
            // lastupdated: firebase.firestore.Timestamp.now(),
            // generatingPDFchecklist: JSON.stringify(checklist),
          })
          .then(() => {
            // this.globalservice.updateParentParent(
            //   this.dbpathservice.dbPathChecklist(
            //     checklist,
            //     checklist.objectid,
            //     checklist.projectid
            //   )
            // );
            this.snackbarservice.openSnackBar(
              'Avvik signert ut av ' + user.firstname,
              ''
            );
            this.dialog.closeAll();
          });
      }
    });
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  }

  removeDeviationSignature(checklistitem: ChecklistItem) {
    // this.locationservice
    //   .getPosition()
    //   .then(() => {
    // this.sharedservice.user.pipe(take(1)).subscribe((u) => {
    //   if (u !== null) {
    //     const user = u[0];
    checklistitem.deviationSignedBy = null;
    // checklistitem.location = pos;
    console.log('Fjerner signatur...');
    const doc: AngularFirestoreDocument = this.dbDocChecklistItem(
      checklistitem.id
    );
    doc
      .update({
        deviationSignedBy: checklistitem.deviationSignedBy,
        // location: checklistitem.location,
      })
      .then(() => {
        this.snackbarservice.openSnackBar('Signatur fjernet', '');
        // this.dialog.closeAll();
      });
    // }
    // });
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  }

  private stripChecklistItems(actions: DocumentChangeAction<ChecklistItem>[], stripHard?: boolean) {
    return actions.map((action) => {
      const checklistItem = {...action.payload.doc.data() as ChecklistItem};
      const id = action.payload.doc.id;
      checklistItem.parentid = action.payload.doc.ref.parent.parent.id;
      delete checklistItem.z_filesstring;
      checklistItem.editedBy = this.dateservice.getLatestEditedByFromArray(checklistItem.editedBy);
      const fromCache = action.payload.doc.metadata.fromCache;
      if (stripHard) {
        return {
          id,
          fromCache,
          checklistid: checklistItem.checklistid,
          objectid: checklistItem.objectid,
          parentid: checklistItem.parentid,
          projectid: checklistItem.projectid,
          text: checklistItem.text,
          comment: checklistItem.comment,
          valuecomment: checklistItem.valuecomment,
          type: checklistItem.type,
          value: checklistItem.value,
          deviationcomment: checklistItem.deviationcomment,
          deviationSignedByString: checklistItem.deviationSignedByString,
          status: checklistItem.status
        } as ChecklistItem;
      }
      return {id, fromCache, ...checklistItem} as ChecklistItem;
    });
  }

  private dbDocChecklist(checklist?: Checklist, objectid?: string, projectid?: string) {
    if (checklist.receiptform) {
      return this.afs.doc(
        this.dbpathservice.dbPathReceiptform(checklist, projectid)
      );
    } else {
      return this.afs.doc(
        this.dbpathservice.dbPathChecklist(checklist, objectid, projectid)
      );
    }
  }

  private dbDocChecklistItem(checklistItemId: string, isReceiptform?: boolean) {
    if (isReceiptform) {
      return this.afs.doc(
        this.dbpathservice.dbPathChecklistItem(checklistItemId, isReceiptform)
      );
    } else {
      return this.afs.doc(
        this.dbpathservice.dbPathChecklistItem(checklistItemId)
      );
    }
  }
}
